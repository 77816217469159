import React from 'react';

const PageBanner = ({ text }) => {

  return (
    <div className="bg-b2b-banner bg-center bg-cover w-full h-1/6 pt-32 md:pt-40 md:h-[30%]">
      <h1 className="b2b-glowing-gold-light-5xl sm:b2b-glowing-gold-light-7xl md:b2b-glowing-gold-light-8xl text-center">
        {text}
      </h1>
    </div>
  );
};

export default PageBanner;