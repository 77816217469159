import React from 'react';
import PageBanner from '../components/sections/PageBanner';
import { TEXTS } from '../constants/texts';


const Utilities = () => {

  return (
    <div className="w-full bg-b2b-blue-lightest rounded-b-8xl pb-28">
      <PageBanner text="BLOCK2BLOCK PLATINUM PASS UTILITIES"/>
      <div id className="w-full flex flex-col items-center justify-center mt-14 md:mt-28 relative">
        <div className="bg-b2b-particle-5 bg-cover bg-center w-80 aspect-[653/800] -rotate-45 absolute top-1/2 left-0 transform -translate-x-1/2 scale-[3]"/>
        <div className="bg-b2b-particle-5 bg-cover bg-center w-80 aspect-[653/800] absolute top-0 right-0 transform -translate-y-1/4 translate-x-1/2 scale-[2]"/>
        <div className="bg-b2b-particle-5 bg-cover bg-center w-80 aspect-[653/800] absolute bottom-0 right-0 translate-y-1/2 rotate-180"/>
        <ul className="relative border-l-[10px] border-white max-w-[500px] py-8">
          <div className="absolute w-6 h-6 rounded-full bg-b2b-blue-lightest border-white border-4 top-0 left-0 transform -translate-x-[calc(50%+5px)] -translate-y-1/2"/>
          <div className="absolute w-6 h-6 rounded-full bg-b2b-blue-lightest border-white border-4 bottom-0 left-0 transform -translate-x-[calc(50%+5px)] translate-y-1/2"/>
          {TEXTS.UTILITIES.map((utility) => {

            return (
              <li className="mb-10 ml-32 relative">
                <div className="absolute w-10 h-10 rounded-full -left-32 transform -translate-x-[calc(50%+5px)] bg-white"/>
                <h2 className="text-2xl font-semibold text-b2b-gold">{utility.title}</h2>
                <p className="text-2xl font-octosquares text-white">{utility.description}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );
};

export default Utilities;