import { differenceInMilliseconds } from 'date-fns';
import React, { useCallback, useState } from 'react';
import useInterval from '../../hooks/useInterval';
import B2BLogo from '../../assets/b2b-logo.svg';
import B2BButton from '../../components/buttons/B2BButton';
import { TEXTS } from '../../constants/texts';

export const CACHE_LAUNCH_KEY = 'hasLaunched';

const isWithin10Seconds = (ms) => {
  return ms <= ((10 + 1) * 1000);
}

const LaunchCountdown = ({ dateOfLaunch, onLaunched }) => {
  const [, setWithin10Secs] = useState(false);
  const [, setCountdown] = useState(false);
  const [launchDate, setLaunchDate] = useState(new Date(dateOfLaunch));
  const [showButton, setShowButton] = useState(false);

  useInterval(() => {
    if(launchDate) {
      const remainingMS = differenceInMilliseconds(launchDate, new Date());
      if(isWithin10Seconds(remainingMS)) {
        setWithin10Secs(true);
        setCountdown(Math.floor(remainingMS/1000));
        if(remainingMS < (1 * 1000)) {
          setLaunchDate(false);
          setShowButton(true);
        }
      }
    }
  }, 1000);

  const goToHome = useCallback(() => {
    window.localStorage.setItem(CACHE_LAUNCH_KEY, 'true');
    onLaunched(true);
  }, [onLaunched]);

  return (
    <div className="b2b-countdown-container bg-b2b-blue-lightest fixed w-screen h-screen text-white z-50 flex flex-col justify-between items-center">
      <div className="b2b-welcome-header bg-b2b-banner bg-center bg-cover w-full h-1/6 md:h-[30%] relative mb-28 sm:mb-36 md:mb-40">
        <img
          className="absolute left-1/2 top-full transform -translate-x-1/2 -translate-y-1/2 bg-b2b-blue-light rounded-full h-52 w-52 sm:h-72 sm:w-72 md:h-80 md:w-80"
          src={B2BLogo}
          alt=""
        />
      </div>
      <div className="mx-8 text-center flex flex-col justify-center items-center">
        <h3 className="b2b-welcome-text md:text-7xl text-3xl my-4 sm:my-6 md:my-8">WELCOME TO BLOCK2BLOCK</h3>
        <p className="b2b-who-are-we sm:w-1/2 md:w-3/4 b2b-who-are-we">{TEXTS.WHO_ARE_WE}</p>
        {
          showButton ?
          <B2BButton
            text={'GO TO HOMEPAGE'}
            onClick={goToHome}
            btnClassName="b2b-launching-soon my-16 md:my-8 text-2xl md:text-4xl"
          /> : 
          <h1 className="b2b-launching-soon text-5xl md:text-9xl my-16 md:my-8 text-b2b-gold">LAUNCHING SOON</h1>
        }
      </div>
      <p className="b2b-copyright mb-6 text-b2b-gold">&copy; 2022 BLOCK2BLOCK STUDIOS</p>
      <div className="b2b-trailing-text-overlay bg-black/75 fixed left-0 top-0 w-screen h-screen">
        <div className="b2b-trailing-text-container bg-b2b-blue-lightest absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full py-10 text-white text-center">
          <p className="md:text-13xl">BLOCK2BLOCK</p>
          <p className="md:text-13xl">BLOCK2BLOCK</p>
          <p className="md:hidden">BLOCK2BLOCK</p>
          <p className="md:hidden">BLOCK2BLOCK</p>
          <p className="md:hidden">BLOCK2BLOCK</p>
          <p className="md:hidden">BLOCK2BLOCK</p>
        </div>
      </div>
    </div>
  );
};

export default LaunchCountdown;