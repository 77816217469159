import React, { useMemo } from 'react';
import B2BButton from '../components/buttons/B2BButton';
import B2BLogo from '../assets/b2b-logo.svg';
import { TEXTS } from '../constants/texts';

const Home = () => {
  const passAngles = useMemo(() => {
    const angles = [];
    angles.push({
      className: 'bg-cover transform scale-[2.75] origin-right',
      hoverScale: 3.75,
    });
    angles.push({
      className: 'bg-cover transform scale-[2.75] origin-center',
      hoverScale: 3.75
    });
    angles.push({
      className: 'bg-cover transform scale-[1.9] origin-top-right',
      hoverScale: 2.9,
    });
    angles.push({
      className: 'bg-cover transform scale-[2.75] origin-left',
      hoverScale: 3.75
    });
    angles.push({
      className: 'bg-contain bg-center',
      hoverScale: 2,
    });
    angles.push({
      className: 'bg-cover transform scale-[1.9] origin-bottom-left',
      hoverScale: 2.9
    });
    return angles;
  }, []);
  
  return (
    <div className="w-full bg-b2b-blue-dark rounded-b-8xl">
      <div id="welcome" className="bg-b2b-home-welcome pt-28 px-12 bg-cover w-full h-screen bg-center relative">
        <h1 className="b2b-glowing-white-6xl leading-[4rem] mb-6 lg:mb-0 lg:b2b-glowing-white-8xl lg:leading-[8rem]">
          WELCOME TO BLOCK2BLOCK
        </h1>
        <div className="flex flex-col md:flex-row md:items-center">
          <h1 className="flex-1 b2b-glowing-gold-6xl leading-[3.25rem] lg:b2b-glowing-gold-8xl lg:leading-[5.5rem] !text-white">
            collect your block2block platinum pass!
          </h1>
          <div
            className="bg-b2b-pass lg:flex-1 bg-center bg-contain bg-no-repeat h-[200px] w-[200px] md:h-[300px] md:w-[300px] lg:h-[400px] lg:w-[400px]"
          />
        </div>
        <div>
          <B2BButton
            text="GET NOW"
            btnClassName="text-4xl no-underline mb-4"
            txtClassName="mx-6"
            to="/platinum-pass"
          />
        </div>
        <span className="b2b-glowing-white-2xl ml-6">also available at opensea</span>
      </div>
      <div 
        className="bg-b2b-metaworld w-full h-[738px] bg-cover bg-center relative"
      >
        <h2 id="about" className="absolute pt-32 bottom-24 left-1/2 transform -translate-x-1/2 b2b-glowing-gold-6xl text-b2b-gold">ABOUT US</h2>
      </div>
      <div className="bg-b2b-tech-lines w-full h-[738px] pt-8 px-12 lg:px-20 bg-cover bg-center flex flex-col items-end">
        <div className="flex flex-col sm:flex-row w-full justify-center items-center">
          <img
            className="rounded-full h-52 w-52 sm:h-72 sm:w-72 md:h-80 md:w-80 lg:mx-12 xl:mr-40 2xl:h-96 2xl:w-96"
            src={B2BLogo}
            alt=""
          />
          <p className="font-octosquares text-lg text-white mx-8 text-center max-w-[400px] lg:mx-12 xl:ml-40 2xl:text-2xl">{TEXTS.WHO_ARE_WE}</p>
        </div>
        <B2BButton
          text="HOME PAGE"
          btnClassName="mt-20"
          toHash="/#welcome"
        />
      </div>
      <div id="platinum-pass" className="bg-black h-screen w-full px-12 flex flex-col lg:flex-row justify-evenly items-center">
        <div className="bg-b2b-particle-1 h-[600px] w-[600px] bg-contain bg-no-repeat bg-center flex-1 relative mb-24 lg:mb-0 flex justify-center items-center">
          <div
            className="bg-b2b-diagonal-squares absolute bottom-0 left-0 h-1/2 w-1/2 bg-cover"
          />
          <div
            className="bg-b2b-pass h-2/3 w-2/3 bg-contain bg-no-repeat bg-center z-10"
          />
        </div>
        <div className="flex flex-col items-start flex-1">
          <h2 className="b2b-glowing-gold-6xl">PLATINUM NFT PASS</h2>
          <p className="font-octosquares b2b-glowing-white-2xl">
          {TEXTS.HOW_TO_JOIN}
          </p>
          <B2BButton
            text="GET NOW"
            btnClassName="text-xl no-underline mt-4"
            txtClassName="mx-6"
            to="/platinum-pass"
          />
        </div>
      </div>
      <div className="b2b-home-last h-screen w-full px-12 pt-40 relative rounded-b-8xl">
        {/* <div className="b2b-home-last-overlay z-0 rounded-b-8xl"/> */}
        <div
          className="bg-b2b-particle-2 absolute left-0 bottom-0 w-full h-[650px] bg-cover pointer-events-none"
        />
        <div
          className="bg-b2b-particle-3 absolute right-0 top-0 w-[450px] h-[450px] bg-cover pointer-events-none"
        />
        <div className="flex flex-row justify-between flex-wrap">
        {
          passAngles.map((angle, idx) => {

            return (
              <div key={idx} className="my-4 md:my-0 w-[33%] md:w-[16%] aspect-square overflow-hidden">
                <div
                  className={`
                    w-full h-full
                    bg-b2b-glowing-pass-small
                    transition-transform
                    hover:scale-[5]
                    z-10
                    ${angle.className}
                  `}
                />
              </div>
            )
          })
        }
        </div>
        <B2BButton
          text="HOME PAGE"
          btnClassName="mt-20 float-right"
          toHash="/#welcome"
        />
      </div>
    </div>
  );
};

export default Home;