import React, { Suspense, useCallback, useState } from 'react';
import AppLayout from './layouts/AppLayout';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import { Error404, Error500, ErrorNotFound } from './pages/errors';
import Spinner from './components/loading/Spinner';
import Minting from './pages/Minting';
import Utilities from './pages/Utilities';
import { getTime } from 'date-fns';

export const AppContext = React.createContext({});

function App() {
  const [msgs, setMsgs] = useState([]);
  const removeMsg = useCallback((id) => {
    setMsgs(currMsgs => currMsgs.filter(value => value.id !== id));
  }, []);

  const addMsg = useCallback((text, type = 'success') => {
    const msgId = getTime(new Date());
    setMsgs(currMsgs => [
      ...currMsgs,
      {
        text,
        type,
        id: msgId,
      }
    ]);

    setTimeout(() => removeMsg(msgId), 5000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppContext.Provider value={{ msgs, addMsg }} >
      <AppLayout>
        <Suspense fallback={<Spinner/>}>
        <Routes>
          <Route index element={<Home/>}/>
          <Route path="platinum-pass" element={<Minting/>}/>
          <Route path="utilities" element={<Utilities/>}/>
          <Route path="errors">
            <Route index element={<Error500/>}/>
            <Route path="404" element={<Error404/>}/>
          </Route>
          <Route path="*" element={<ErrorNotFound/>}/>
        </Routes>
        </Suspense>
      </AppLayout>
    </AppContext.Provider>
  );
}

export default App;
