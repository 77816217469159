export const TEXTS = {
  WHO_ARE_WE: 'A tight- knit, fun group of innovators, creatives, collectors and blockchain experts uniting with a mission: to onboard more local business and consumers to the ever growing space of blockchain.',
  HOW_TO_JOIN: `To join the community, one must have “The BLOCK2BLOCK Platinum NFT Pass” as a membership keycard. This will give you access on our Discord Server, exclusive events, gain real world utilities and a lot more once you're a member.`,
  HOW_TO_GET_ACCESS: `To get access to THE EXCLUSIVE UTILITIES for BLOCK2BLOCK PLATINUM PASS , please connect YOUR WALLET TO AVAIL THE MEMBERSHIP PASS.`,
  UTILITIES: [
    {
      title: 'Access to Private Server',
      description: 'Get access to an exclusive private discord server where we share everything about crypto, web3, blockchain and NFT Alpha.',
    },
    {
      title: 'Access to Exclusive Events',
      description: 'Get access to exclusive IRL events.',
    },
    {
      title: 'Real world utilites',
      description: 'Use the platinum pass as a key to select brands and  establishments for discounts and privileges. ',
    },
    {
      title: 'Real world assets ',
      description: 'The DCB will purchase IRL assets which can be enjoyed by the community.',
    },
    {
      title: 'Free Mint on our future PFP project',
      description: 'As an owner of our platinum pass, you will get Free mint on our future NFT pfp project.',
    },
    {
      title: 'Exclusive Merch',
      description: 'Bridging physical to digital (AKA Phygital). We go beyond basic wear.  ',
    },
    {
      title: 'Right to explore future partnerships',
      description: 'Want to expand your portfolio and have your own NFTs or add more business to your resume? You have an access and right to explore future partnerships with the right people in the space. ',
    },
    {
      title: 'Community Service ',
      description: 'DCB will vote on which charity the community will assist. ',
    },
  ]
}