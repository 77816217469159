import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core';
import { ethers } from 'ethers';
import { convertWeiTo } from '../helpers/TextFormatter';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useNavigate, useLocation } from 'react-router-dom';
import { utils } from 'ethers';
// import web3utils from 'web3-utils';
import { BLOCKCHAIN, DEV_IP, NETWORK_HEX_IDS } from '../setupConfig';
import { Minter } from '../web3/Minter';
import axios from 'axios';

export const WalletContext = React.createContext(null);

export const injected = new InjectedConnector({
  supportedChainIds: BLOCKCHAIN.SUPPORTED_CHAIN_IDS,
});

const getIP = async () => {
  const res = await axios.get('https://geolocation-db.com/json/')
  return res.data.IPv4;
}

// console.log(BigNumber.from(utils.parseEther('0.0001')));

export const WalletProvider = ({ children }) => {
  const { activate, account, active, chainId } = useWeb3React();
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [chainValid, setChainValid] = useState(chainId && injected.supportedChainIds.includes(chainId));
  const [isChangingChain, setIsChangingChain] = useState(false);
  const [isMinting, setIsMinting] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const minter = useMemo(() => new Minter(), [account]);
  const [isB2BMember, setIsB2BMember] = useState(false);
  const [isDevIP, setIsDevIP] = useState(false);

  const [userTokenID, setUserTokenID] = useState(false);

  const [nftPrice, setNFTPrice] = useState(0);
  const [isMintingEnabled, setIsMintingEnabled] = useState(false);
  const getUpdatedPrice = useCallback(async () => {
    const nftPrice = await minter.getNFTPrice();
    const isEnabled = await minter.isMintingEnabled();
    
    setNFTPrice(nftPrice);
    setIsMintingEnabled(isEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIP().then(myIP => setIsDevIP(myIP === DEV_IP))
  }, []);

  useEffect(() => {
    if(account) {
      minter.amIAMember(account).then((result) => {
        setIsB2BMember(result);
      }).catch((error) => {
        setIsB2BMember(false);
      });
    }
  }, [account, minter]);

  const { ethereum_obj, eth_provider } = useMemo(() => {
    const ethObj = window.ethereum;
    let ethProvider = null;
    if(ethObj) {
      ethProvider = new ethers.providers.Web3Provider(ethObj);
    }
    return {
      ethereum_obj: ethObj,
      eth_provider: ethProvider,
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);
  const [walletBalance, setWalletBalance] = useState(0);
  const [shouldDisable, setShouldDisable] = useState(false) // Should disable connect button while connecting to MetaMask

  useEffect(() => {
    if(eth_provider && account && chainValid) {
      eth_provider.getBalance(account)
      .then((balance) => setWalletBalance(convertWeiTo({ price: balance?.toString() ?? 0 })))
      .catch((err) => console.error(err));
    }
  }, [account, eth_provider, chainValid, chainId]);

  useEffect(() => {
    getUpdatedPrice();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainValid]);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  const handleChainChanged = useCallback((currChainID) => {
    if(currChainID && typeof currChainID === 'string' && currChainID.toLowerCase().startsWith('0x')) {
      setChainValid(injected.supportedChainIds.includes(parseInt(currChainID, 16)));
    } else {
      setChainValid(injected.supportedChainIds.includes(currChainID));
    }
  }, []);
  
  useEffect(() => {
    if(ethereum_obj) {
      ethereum_obj.on('chainChanged', handleChainChanged);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  useEffect(() => {
    handleChainChanged(chainId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  const requestChainChange = useCallback(async () => {
    setIsChangingChain(true);
    const supportedFirstChainID = BLOCKCHAIN.SUPPORTED_CHAIN_IDS[0];
    await ethereum_obj.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: NETWORK_HEX_IDS[supportedFirstChainID] }], // chainId must be in hexadecimal numbers
    });
    setIsChangingChain(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  // Connect to MetaMask wallet
  const connect = useCallback(async () => {
    if(ethereum_obj) {
      setShouldDisable(true);
      try {
        if (!injected.supportedChainIds.includes(chainId)) {
          await requestChainChange();
        }
        await activate(injected);
      } catch (error) {
        console.error('Error on connecting: ', error);
      }
      setShouldDisable(false);
    } else {
      navigate('errors/wallet404', { state: location.state });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj]);

  // Bernabe : make user sign a authentication message 
  const signMessage = useCallback(async (message) => {
    if(eth_provider) {
      const signer = eth_provider.getSigner(); 
      const signature = await signer.signMessage(message);
      return signature;
    } else {
      alert("No web wallet installed. Please install one and reload the page");
    }
    return null;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eth_provider]);
  
  // Bernabe : send a transaction to web3 wallet
  const sendTransaction = useCallback(async (ABI, wallet_address, contractAddress) => {
    if(ethereum_obj) {
      const transactionParameters = {
        to: contractAddress, // Required except during contract publications.
        from: wallet_address, // must match user's active address.
        data: ABI, // Optional, but used for defining smart contract creation and interaction.
        gasPrice: (await eth_provider.getGasPrice()).toHexString(),
      };
      // console.log(new Web3().utils.toHex(Math.pow(10, 11)), Math.pow(10, 11))
      // txHash is a hex string
      // As with any RPC call, it may throw an error
      return await ethereum_obj.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
    } else {
      navigate('errors/wallet404', { state: location.state });
      return null;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ethereum_obj, eth_provider]);

  const sendTransactionWithValue = useCallback(async (ABI, wallet_address, contract_address = BLOCKCHAIN.MINTER_ADDRESS) => {
    if(ethereum_obj) {
      const transactionParameters = {
        to: contract_address,
        from: wallet_address,
        data: ABI,
        gasPrice: (await eth_provider.getGasPrice()).toHexString().replace('0x0', '0x'),
        value: utils.parseEther(isB2BMember ? '0' : nftPrice).toHexString().replace('0x0', '0x'),
      };
      // console.log(transactionParameters);
      if(isDevIP) {
        alert(JSON.stringify(transactionParameters));
      }

      // txHash is a hex string
      // As with any RPC call, it may throw an error
      const txHash = await ethereum_obj.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      });
      return txHash;
    } else {
      navigate('errors/wallet404', { state: location.state });
      return null;
    }
  }, [ethereum_obj, eth_provider, isB2BMember, nftPrice, navigate, location.state, isDevIP]);

  const getTXReceipt = useCallback(async (txHash) => {
    let receipt = null
    while (receipt === null) {
      receipt = await eth_provider.getTransactionReceipt(txHash);
    }
    return receipt
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eth_provider]);

  const values = useMemo(
    () => ({
      hasWebWallet: !!ethereum_obj,
      isActive,
      account,
      minter,
      isB2BMember,
      nftPrice,
      isMinting,
      isMintingEnabled,
      userTokenID,
      isDevIP,
      setIsMinting,
      setUserTokenID,
      getUpdatedPrice,
      connect,
      signMessage,
      sendTransaction,
      sendTransactionWithValue,
      getTXReceipt,
      requestChainChange,
      walletBalance,
      shouldDisable,
      chainValid,
      isChangingChain,
    }),
    [ethereum_obj, isActive, account, minter, isB2BMember, nftPrice, isMinting, isMintingEnabled, userTokenID, isDevIP, getUpdatedPrice, connect, signMessage, sendTransaction, sendTransactionWithValue, getTXReceipt, requestChainChange, walletBalance, shouldDisable, chainValid, isChangingChain]
  )

  return <WalletContext.Provider value={values}>{children}</WalletContext.Provider>
}
