import React from 'react';

const Block2BlockHeader = () => {
  return (
    <p className="b2b-logo">
      BLOCK2BLOCK
    </p>
  );
};

export default Block2BlockHeader;