import React, { useCallback, useContext, useState } from 'react';
import Header from './Header';
import { LaunchCountdown } from '../pages/landing';
import DiscordIcon from '../assets/socmed/discord.svg';
import FBIcon from '../assets/socmed/facebook.svg';
import InstagramIcon from '../assets/socmed/instagram.svg';
import TwitterIcon from '../assets/socmed/twitter.svg';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/alerts/Alert';
import { AppContext } from '../App';

const LAUNCH_DATE = process.env.REACT_APP_LAUNCH_DATE || "Tue 5 July 2022 18:00:00 GMT+0800 (Singapore Standard Time)";
// const LAUNCH_DATE = "Wed 29 June 2022 18:13:00 GMT+0800 (Singapore Standard Time)";
const HAS_LAUNCED = window.localStorage.getItem('hasLaunched') === 'true';
// const HAS_LAUNCED = true;

const AppLayout = ({ children }) => {
  const { msgs } = useContext(AppContext);
  const [hasLaunched, setHasLaunch] = useState(HAS_LAUNCED);
  const navigate = useNavigate();
  // return hasLaunched ? null : <LaunchCountdown onLaunched={setHasLaunch}/>

  const toggleLaunch = useCallback(() => {
    if(hasLaunched) {
      navigate('/');
    }
    setHasLaunch(!hasLaunched);
  }, [hasLaunched, navigate]);

  return (
    <div className="w-screen h-screen flex flex-col justify-start align-center bg-b2b-gold overflow-y-auto overflow-x-hidden custom-scrollbar">
      {
        hasLaunched ?
        <>
        <Header/>
        <main className="relative flex-1">
          {children}
          <div className="fixed top-[150px] right-8 z-[999] w-[250px] max-w-1/2 pointer-events-none">
            {
              msgs.map((msg) => {
                return (
                  <Alert key={msg.id} {...msg}/>
                );
              })
            }
          </div>
        </main>
        <footer>
          <div className="w-full flex flex-col md:flex-row justify-evenly items-center relative py-24 px-12 md:py-52 md:px-36">
            <h1 className="b2b-glowing-white-6xl mb-12 md:mb-0">
              SEND US A MESSAGE |
              <br/>
              FOLLOW US
            </h1>
            <div className="flex flex-col justify-start">
              {/* <p className="text-2xl text-white font-octosquares">
              123 Anywhere St., Any City <br/>
              Any State, Country <br/>
              (123) 456 7890 <br/>
              </p> */}
              <p className="text-2xl text-b2b-blue-lighter font-bold font-octosquares">block2blockstudios@gmail.com</p>
              <div className="mt-8 flex flex-row justify-start z-10">
                <a 
                  href="https://twitter.com/b2b_studios"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    className="w-10 h-10 mr-4 stroke-b2b-blue-lighter"
                    src={TwitterIcon}
                    alt=""
                  />
                </a>
                <a 
                  href="https://www.facebook.com/Block2Block-Community-105057595561848/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    className="w-10 h-10 mr-4 stroke-b2b-blue-lighter"
                    src={FBIcon}
                    alt=""
                  />
                </a>
                <a 
                  href="https://discord.com/invite/g5pMgB6f"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    className="w-10 h-10 mr-4 stroke-b2b-blue-lighter"
                    src={DiscordIcon}
                    alt=""
                  />
                </a>
                <a 
                  href="https://instagram.com/block2block.studios?igshid=YmMyMTA2M2Y="
                  target="_blank"
                  rel="noreferrer"
                >
                  <img 
                    className="w-10 h-10 mr-4 stroke-b2b-blue-lighter"
                    src={InstagramIcon}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="absolute left-10 bottom-4 flex flex-row justify-start">
              <p className="text-lg text-b2b-blue-lighter font-semibold font-octosquares">@ 2022 BLOCK2BLOCK STUDIOS |{' '}</p>
              <p className="text-lg text-b2b-blue-lighter font-semibold font-octosquares">{' '}TERMS OF USE |{' '}</p>
              <p className="text-lg text-b2b-blue-lighter font-semibold font-octosquares">{' '}PRIVACY POLICY</p>
            </div>
          </div>
        </footer>
        </> :
        <LaunchCountdown dateOfLaunch={LAUNCH_DATE} onLaunched={setHasLaunch}/>
      }
      {
        !HAS_LAUNCED && process.env.NODE_ENV !== 'production' ?
        <button className="b2b-beta-btn" data-testing={hasLaunched} onClick={toggleLaunch}/> :
        null
      }
    </div>
  )
};

export default AppLayout;