import React, { useMemo } from 'react';
import B2BButton from '../components/buttons/B2BButton';
import PageBanner from '../components/sections/PageBanner';
import { TEXTS } from '../constants/texts';
import B2BLogo from '../assets/b2b-logo.svg';
import WalletButton from '../components/buttons/WalletButton';
import { useWeb3Wallet } from '../hooks/useWeb3Wallet';
import { OPENSEA_BASE_URL, OPENSEA_COLLECTION_URL } from '../setupConfig';


const Minting = () => {
  const { isB2BMember, nftPrice, isMintingEnabled, account, userTokenID } = useWeb3Wallet();
  const openseaLink = useMemo(() => {
    if(account) {
      let tokenGenerated = userTokenID || window.localStorage.getItem(account);
      return tokenGenerated ? `${OPENSEA_BASE_URL}${tokenGenerated}` : '';
    }
    return '';
  }, [account, userTokenID]);

  return (
    <div className="w-full bg-b2b-blue-lightest rounded-b-8xl">
      <div
        className="bg-b2b-particle-6 bg-cover bg-center absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 scale-[3] w-[250px] aspect-[734/716] z-[5]"
      />
      <PageBanner text="PLATINUM PASS"/>
      <div id="buy-now" className="w-full flex flex-col justify-center mt-14 md:mt-28 overflow-hidden">
        <h2 className="b2b-glowing-white-4xl md:b2b-glowing-white-6xl ml-6 md:ml-8">BUY NOW</h2>
        <div className="flex flex-col md:flex-row md:justify-between md:items-end z-10">
          <div className="bg-[#51402B] w-[90%] max-w-3xl h-[700px] md:w-1/2 rounded-r-4xl flex justify-center xl:justify-end items-center relative">
            <div className="bg-b2b-particle-4 absolute -left-16 -bottom-32 w-96 h-96 bg-cover bg-center z-[11]"/>
            <div className="bg-b2b-diagonal-squares absolute left-20 w-40 h-40 -bottom-8 md:h-44 md:w-44 md:left-22 md:bottom-4 lg:w-52 lg:h-52 lg:left-20 lg:-bottom-8 xl:w-64 xl:h-64 xl:left-44 xl:-bottom-24 bg-cover bg-center z-[11]"/>
            <div className="bg-b2b-particle-3 absolute right-28 top-1/2 w-96 h-96 bg-cover bg-center transform -translate-y-1/2 origin-center scale-[2.5] opacity-75 z-[0]"/>
            <div
              className="bg-contain bg-center w-[80%] aspect-square xl:w-[450px] xl:h-[450px] xl:mr-28 z-10 shadow-lg shadow-b2b-gold-light"
            >
              <video loop muted autoPlay>
                <source src={"/videos/b2b-platinum-pass-nft.mp4"} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="w-full md:ml-16 md:w-1/2 mb-16 mt-8 md:mt-0 flex flex-col items-center md:items-start z-10">
            <h2 className="b2b-glowing-gold-light-4xl md:b2b-glowing-gold-light-6xl">PLATINUM NFT PASS</h2>
            <hr className="border-solid border-1 border-white w-[calc(95%)] lg:w-[calc(100%-64px)]"/>
            <h3 className="b2b-glowing-white-2xl md:b2b-glowing-white-4xl my-6">PRICE</h3>
            {
              isMintingEnabled || isB2BMember ? 
              <h3 className="b2b-glowing-gold-light-2xl md:b2b-glowing-gold-light-4xl mb-10">ΞETH {isB2BMember ? 'FREE' : nftPrice}</h3> :
              <h3 className="b2b-glowing-gold-light-2xl md:b2b-glowing-gold-light-4xl mb-10">Minting not Open</h3>
            }
            <hr className="border-solid border-1 border-white w-[calc(95%)] lg:w-[calc(100%-64px)]"/>
            {
              openseaLink ?
              <B2BButton
                to={openseaLink}
                text="Check your NFT at Opensea"
                btnClassName="mt-6" 
                txtClassName="text-2xl"
                target="_blank"
              /> :
              <WalletButton buyText='BUY HERE' btnClassName="mt-6" txtClassName="text-2xl" arrowClassName="h-8 w-8"/>
            }
            <span className="b2b-glowing-white-lg mt-6 mb-2">also available at opensea</span>
            <B2BButton 
              text="CLICK HERE TO BUY THE PASS AT OPENSEA"
              btnClassName="no-underline max-w-[90%]" 
              txtClassName="text-lg" 
              arrowClassName="h-6 w-6"
              to={OPENSEA_COLLECTION_URL}
              target="_blank"
            />
          </div>
        </div>
        <div className="mt-16 w-full flex flex-col items-center justify-center relative z-20">
          <div className="bg-b2b-banner-rotate-90 bg-cover h-full aspect-[555/1920] absolute top-0 right-0 -z-1 transform translate-x-1/2">
            <div
              className="bg-b2b-particle-6 bg-cover bg-center absolute right-0 top-0 transform -translate-x-1/2 -translate-y-1/2 scale-[3] w-[250px] aspect-[734/716] pointer-events-none"
            />
            <img
              className="absolute left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-b2b-blue-light rounded-full h-52 w-52 sm:h-72 sm:w-72 md:h-80 md:w-80 pointer-events-none"
              src={B2BLogo}
              alt=""
            />
          </div>
          <p className="font-octosquares text-lg text-white text-left w-[400px] mx-8 mt-8 md:mt-32 z-10">{TEXTS.HOW_TO_JOIN}</p>
          <div
            className="my-32 lg:ml-[-30rem] xl:ml-[-40rem] relative"
          >
            <div className="animate-pulse bg-b2b-gold-light w-8 h-8 rounded-full blur-lg absolute left-4 bottom-4 z-0" />
            <div className="animate-pulse bg-b2b-gold-light w-16 h-16 rounded-full blur-xl absolute left-1/2 bottom-1/2 z-0" />
            <div className="animate-pulse bg-b2b-gold-light w-32 h-32 rounded-full blur-xl opacity-10 absolute right-0 top-0 z-0" />
            <div className="animate-pulse bg-b2b-gold-light w-16 h-16 rounded-full blur-md opacity-10 absolute right-32 top-32 z-0" />
            <div className="animate-pulse bg-b2b-gold-light w-32 h-32 rounded-full blur-xl opacity-10 absolute left-32 bottom-8 z-0" />
            <div className="b2b-floating-pass bg-b2b-pass w-[350px] lg:w-[500px] aspect-[983/611] bg-center bg-cover z-[1]"/>
          </div>
          <div className="lg:mr-[-20rem] xl:mr-[-40rem] 2xl:mr-[-50rem] mt-60 lg:my-8 flex flex-col items-center z-10">
            <p className="text-xl text-white text-center w-[300px]">{TEXTS.HOW_TO_GET_ACCESS}</p>
            <B2BButton text="CONNECT TO WALLET" btnClassName="mt-6 no-underline" txtClassName="text-4xl" arrowClassName="" toHash={'#buy-now'}/>
          </div>
          <div className="mt-16 lg:ml-[-20rem] xl:ml-[-40rem] 2xl:ml-[-60rem] transform translate-y-1/4 relative">
            <div className="bg-b2b-particle-5 w-full aspect-[653/800] bg-cover bg-center top-0 left-0 absolute transform scale-[2] rotate-180"/>
            <div className="bg-b2b-pass w-[350px] lg:w-[500px] aspect-[983/611] bg-center bg-cover z-[1]"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Minting;