
// export const BLOCKCHAIN_MODE = process.env.REACT_APP_BLOCKCHAIN_MODE;
export const BLOCKCHAIN_MODE = 'PROD';

const CHAIN_IDS = {
  PROD: [1],
  TEST: [4],
  LOCAL: [1337],
};

export const DEV_IP = process.env.REACT_APP_DEV_IP || "110.93.87.221";

const MINTER_ADDRESSES = {
  PROD: '0xCf639EA4CdA7a042fF47E6692e320ed70e010Fb3',
  TEST: '0x7565079f2688514A21328caF88Aed0C448eE31C0',
  LOCAL: '0x5FbDB2315678afecb367f032d93F642f64180aa3',
};

export const NETWORK_HEX_IDS = {
  4: '0x4',
  1: '0x1',
  1337: '0x539',
}

const OPENSEA_CONFIGS = {
  TEST: {
    SUBDOMAIN: 'testnets.',
    ASSET_SUB: 'rinkeby',
  },
  PROD: {
    SUBDOMAIN: '',
    ASSET_SUB: 'ethereum',
  },
  LOCAL: {
    SUBDOMAIN: '',
    ASSET_SUB: '',
  }
}

const TestMinterJSON = require(`./web3/jsons/test.json`);
const ProdMinterJSON = require(`./web3/jsons/prod.json`);
const LocalMinterJSON = require(`./web3/jsons/local.json`);


const BLOCKCHAIN_CONFIGS = {
  PROD: {
    MINTER_ADDRESS: MINTER_ADDRESSES.PROD,
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.PROD,
    MINTER_JSON: ProdMinterJSON,
  },
  TEST: {
    MINTER_ADDRESS: MINTER_ADDRESSES.TEST,
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.TEST,
    MINTER_JSON: TestMinterJSON,
  },
  LOCAL: {
    MINTER_ADDRESS: MINTER_ADDRESSES.LOCAL,
    SUPPORTED_CHAIN_IDS: CHAIN_IDS.LOCAL,
    MINTER_JSON: LocalMinterJSON,
  },
};

export const BLOCKCHAIN = BLOCKCHAIN_CONFIGS[BLOCKCHAIN_MODE];
export const OPENSEA_BASE_URL = `https://${OPENSEA_CONFIGS[BLOCKCHAIN_MODE].SUBDOMAIN}opensea.io/assets/${OPENSEA_CONFIGS[BLOCKCHAIN_MODE].ASSET_SUB}/${BLOCKCHAIN.MINTER_ADDRESS}/`;
export const OPENSEA_COLLECTION_URL = `https://${OPENSEA_CONFIGS[BLOCKCHAIN_MODE].SUBDOMAIN}opensea.io/collection/block2block`;