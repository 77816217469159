import React from 'react';
import { ErrorScreen } from '../../components/errors';
import B2BButton from '../../components/buttons/B2BButton';

const Error404 = () => {

  return (
    <ErrorScreen
      title={'Error 404'}
      header={'Page not found.'}
      description="Sorry, the page you're looking for does not exist"
      className='!h-screen'
    >
      <B2BButton to="/" text="Go back home" btnClassName="no-underline"/>
    </ErrorScreen>
  );
};

export default Error404;