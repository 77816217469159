import React from 'react';
import logo from '../../assets/b2b-logo.svg';

const ErrorScreen = ({ title, header, description, children, className = '' }) => {
  return (
    <div className={`
      w-100 h-full 
      bg-b2b-blue-dark 
      flex 
      justify-center 
      items-center 
      flex-col 
      px-8 
      pt-28
      ${className}
    `}>
      <img
        className="h-48 w-auto sm:h-60 my-4"
        src={logo}
        alt="App Logo"
      />
      <h2 className="text-xl my-2 text-b2b-gold font-semibold tracking-wide uppercase">{title}</h2>
      <h1 className="text-3xl font-bold text-white">{header}</h1>
      <p className="mt-4 max-w-2xl text-xl text-center text-gray-500 lg:mx-auto">{description}</p>
      <div className="flex flex-row justify-between items-center my-4">
        {children}
      </div>
    </div>
  )
};

export default ErrorScreen;
