import React from 'react';
import { Navigate } from 'react-router-dom';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate() {
    if(this.state.hasError) {
      this.setState({ hasState: false });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <Navigate to="errors/500" replace/>
    }

    return this.props.children; 
  }
};

export default ErrorBoundary;